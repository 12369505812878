import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import media from '../styles/media'
import { Link } from "gatsby"

import SEO from "../components/seo"

const FourDiv = styled.div`
  width: 100vw;
  height: 100vh;

  .bg {
    width: 100vw;
    height: 100vh;
  }

  .text {
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows:  1fr 1fr 1fr;
    color: black;
    .info {
      width: 80vw;
      margin: 0 10vw;
      grid-column: 1;
      grid-row: 2/3;
      h1 {
        font-family: 'Merriweather';
         
        font-style: normal;
        font-weight: normal;
        font-size: 40px;
        line-height: 64px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
    }
    a{
      font-size: 12px;
      line-height: 14px;
      color: white;
    }
    button {
      color: white;
      width: 80vw;
      margin: 0;
      height: 40px;
      text-align: center;
      text-transform: uppercase;
      background-color: #64bf60;
      margin-top: 15px;
      border: none;
    }
  }

  ${media.laptop`
    .text {
      width: 100vw;
      height: 100vh;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows:  1fr 1fr 1fr;
      .info {
        grid-column: 2/3;
        grid-row: 2/3;
        margin: 0 auto;
        width: 400px;
        h1 {
          font-size: 48px;
          line-height: 67px;
          text-align: center;
        }
        p {
          font-size: 20px;
          line-height: 32px;
        }
        a {
          font-size: 14px;
          line-height: 16px;
        }
        button {
          width: 400px;
          height: 50px;
        }
      }
    }
  `}
`

const NotFoundPage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "large-logo.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <>
    <SEO title="404: Not found" />
    <FourDiv>
      <BackgroundImage
        className="bg"
        fluid={data.desktop.childImageSharp.fluid}
      >
        <div className="text">
          <div className="info">
            <h1>Page not found</h1>
            <p>Looks like you've followed a broken link or entered a URL that doesn't exist on this site.</p>
            <Link to="/"><button>Back to home</button></Link>
          </div>
        </div>
      </BackgroundImage>
    </FourDiv>
    </>
  )
}

export default NotFoundPage
